
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useEffect } from 'react';
const ThirdPartyCSSWrapper: React.FC = () => {
    useEffect(() => {
        const loadStyles = async () => {
            await Promise.all([
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                import('lightgallery/css/lg-thumbnail.css'),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                import('lightgallery/css/lightgallery.css'),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                import('lightgallery/css/lg-zoom.css'),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                import('swiper/css'),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                import('swiper/css/navigation'),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                import('swiper/css/pagination'),
            ]).then(() => {
                // display elements now that css has loaded
                import('./global-dynamic-styles');
            });
        };
        loadStyles();
    }, []);
    return <></>;
};
export default ThirdPartyCSSWrapper;

    async function __Next_Translate__getStaticProps__19229fd8321__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//global-dynamic-styles',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19229fd8321__ as getStaticProps }
  